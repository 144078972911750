import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    title: String,
    value: [Number, String],
    tooltip: String
  }
};