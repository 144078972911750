var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dashboard-dblock",
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "dashboard-dbtline" },
        [
          _c("span", { staticClass: "dashboard-dbtitle" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _vm.tooltip
            ? _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "dark",
                    content: _vm.tooltip,
                    placement: "top"
                  }
                },
                [
                  _c("i", {
                    staticClass: "el-icon-question",
                    staticStyle: { "vertical-align": "middle", color: "#444" }
                  })
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c("p", { staticClass: "dashboard-dbvalue" }, [_vm._v(_vm._s(_vm.value))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }